<template>
	<div class="bullMarket-withdraw">
		<div class="el-content mb12">
			<a-space>
				<a-select v-model:value="search.option_status" placeholder="请选择查询状态">
					<a-select-option value="">请选择查询状态</a-select-option>
					<a-select-option value="0">申请中</a-select-option>
					<a-select-option value="1">已同意</a-select-option>
					<a-select-option value="2">已拒绝</a-select-option>
					<a-select-option value="2">处理中</a-select-option>
					<a-select-option value="4">人工查错</a-select-option>
				</a-select>
				<a-input-search style="width: 400px;" 
					v-model:value="search.key" 
					placeholder="请输入查询关键字" 
					enter-button 
					@search="getBullWithdraw(1,info.limit)" />
			</a-space>
		</div>
		
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'交易单号',dataIndex:'tr_id'},
				{title:'提现用户',dataIndex:'user',slots:{customRender:'user'}},
				{title:'提现金额',dataIndex:'price',slots:{customRender:'price'}},
				{title:'Fcs转出到银行卡',dataIndex:'fcs',slots:{customRender:'fcs'}},
				{title:'提现状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'申请时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #user="{record}">
					<div style="display: flex;" v-if="record.account">
						<a-avatar size="medium" :src="record.account.avatar_url"/>
						<div style="margin-left: 4px;">
							<div><span>{{record.account.nickname}}</span></div>
							<div><span class="f12">user_id:</span>{{record.account.user_id}}</div>
							<div><span class="f12">真实姓名:</span>{{record.id_name}}</div>
							<div><span class="f12">银行卡号:</span>{{record.bank_no}}</div>
						</div>
					</div>
				</template>
				<template #price="{record}">
					<div><span class="否2">提现金额：</span>{{record.amount}}元</div>
					<div><span class="否2">实际到账：</span>{{record.tr_amount}}元</div>
				</template>
				<template #fcs="{record}">
					<div class="mb12">
						<span class="f12">操作:</span>
						<a-tag :color="getBullWithdrawStatusType(record.tr_status)">
							{{getBullWithdrawStatus(record.tr_status)}}
						</a-tag>
					</div>
					<div>
						<span class="f12">查证:</span>
						<a-tag :color="getBullWithdrawStatusType(record.tr_status_check)">
							{{getBullWithdrawStatus(record.tr_status_check)}}
						</a-tag>
					</div>
				</template>
				<template #status="{record}">
					<a-tag color="#999" v-if="record.option_status == 0">申请中</a-tag>
					<a-tag color="#00CC66" v-if="record.option_status == 1">已同意</a-tag>
					<a-tag color="#FF0066" v-if="record.option_status == 2">已拒绝</a-tag>
					<a-tag color="#FF9900" v-if="record.option_status == 3">处理中</a-tag>
					<a-tag color="#FF0066" v-if="record.option_status == 4">人工查错</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<div class="kd-opera-btn" v-if="record.option_status == 0">
							<kd-button type="primary" title="同意提现" icon="ri-check-double-line" @click="agreeWithdraw(record.id)"></kd-button>
							<kd-button type="danger" title="拒绝提现" icon="ri-close-line" @click="showDeny([record.id])"></kd-button>
						</div>
						<a-button size="small" type="danger" @click="retry(record.id)">重试</a-button>
						<router-link :to="{path:'/adhibition/bullMarket/supervise',query:{re_id:record.id,re_ty:'withdrawal'}}">
							<a-button size="small" type="primary">关联</a-button>
						</router-link>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBullWithdraw(info.page,e)}"
					@change="(e)=>{getBullWithdraw(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="deny.show" title="拒绝提现" @ok="denyWithdraw" width="500px" @cancel="deny.show= false">
			<a-textarea v-model:value="deny.desc" placeholder="拒绝理由" :rows="6" />
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{

	computed:{
		getBullWithdrawStatus(){
			return function(s){
				if( s == 0 ) return '未处理'
				if( s == 1 ) return '成功'
				if( s == 2 ) return '失败'
			}
		},
		getBullWithdrawStatusType(){
			return function(s){
				if( s == 0 ) return '#999'
				if( s == 1 ) return '#00CC66'
				if( s == 2 ) return '#FF0066'
			}
		}
	},
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			deny:{
				id:0,
				desc:'',
				show:false,
			},
			search:{
				tr_id:'',
				option_status:'',
			}
		})
		getBullWithdraw(1,state.info.limit)

		function getBullWithdraw(page,limit){
			bullModel.getBullWithdraw(page,limit,state.search,res=>state.info= {limit,...res})
		}

		function showDeny(id){
			state.deny = {
				id:id,
				desc:'',
				show:true
			}
		}

		const agreeWithdraw = id=>bullModel.handleBullWithdraw('agree',{id},()=>{
			getBullWithdraw(state.info.page,state.info.limit)
		})
		const denyWithdraw = ()=>bullModel.handleBullWithdraw("deny",{id:state.desc.id,desc:state.deny.desc},()=>{
			getBullWithdraw(state.info.page,state.info.limit)
		})

		const retry = id=>bullModel.bullWithdrawReTry(id,()=>{
			getBullWithdraw(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getBullWithdraw,
			agreeWithdraw,
			showDeny,
			denyWithdraw,
			retry
		}
	}
}
</script>

<style>
</style>
